exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-all-insurers-js": () => import("./../../../src/templates/allInsurers.js" /* webpackChunkName: "component---src-templates-all-insurers-js" */),
  "component---src-templates-author-single-js": () => import("./../../../src/templates/authorSingle.js" /* webpackChunkName: "component---src-templates-author-single-js" */),
  "component---src-templates-blog-home-js": () => import("./../../../src/templates/blogHome.js" /* webpackChunkName: "component---src-templates-blog-home-js" */),
  "component---src-templates-breed-single-js": () => import("./../../../src/templates/breedSingle.js" /* webpackChunkName: "component---src-templates-breed-single-js" */),
  "component---src-templates-breeds-home-js": () => import("./../../../src/templates/breedsHome.js" /* webpackChunkName: "component---src-templates-breeds-home-js" */),
  "component---src-templates-cat-single-js": () => import("./../../../src/templates/catSingle.js" /* webpackChunkName: "component---src-templates-cat-single-js" */),
  "component---src-templates-compare-js": () => import("./../../../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-insurer-review-js": () => import("./../../../src/templates/insurerReview.js" /* webpackChunkName: "component---src-templates-insurer-review-js" */),
  "component---src-templates-insurer-single-js": () => import("./../../../src/templates/insurerSingle.js" /* webpackChunkName: "component---src-templates-insurer-single-js" */),
  "component---src-templates-insurers-home-js": () => import("./../../../src/templates/insurersHome.js" /* webpackChunkName: "component---src-templates-insurers-home-js" */),
  "component---src-templates-state-single-js": () => import("./../../../src/templates/stateSingle.js" /* webpackChunkName: "component---src-templates-state-single-js" */),
  "component---src-templates-states-home-js": () => import("./../../../src/templates/statesHome.js" /* webpackChunkName: "component---src-templates-states-home-js" */),
  "component---src-templates-submit-js": () => import("./../../../src/templates/submit.js" /* webpackChunkName: "component---src-templates-submit-js" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */),
  "component---src-templates-us-news-js": () => import("./../../../src/templates/usNews.js" /* webpackChunkName: "component---src-templates-us-news-js" */),
  "component---src-templates-versus-js": () => import("./../../../src/templates/versus.js" /* webpackChunkName: "component---src-templates-versus-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */)
}

